import React from "react";
import theme from "theme";
import { Theme, Image, Link, Section, Text, Em, Box, Strong, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
import { AiFillLinkedin } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Humans for AI
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"About | Humans for AI"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI%20Favicon.png?v=2024-02-10T08:45:31.376Z"} type={"image/x-icon"} />
		</Helmet>
		<Section quarkly-title="Header" align-items="center" justify-content="center" padding="16px 0 16px 0">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link position="relative" transition="opacity 200ms ease" quarkly-title="Link" href="/">
				<Image
					z-index="3"
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14:58:12.720Z"
					width="120px"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end" margin="35px 0px 0px 0px">
				<Override
					slot="icon"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					md-height="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					md-width="100%"
					lg-transform="translateY(0px) translateX(0px)"
					md-left={0}
					padding="0px 0 0px 0"
					align-items="center"
					filterPages="/aisc-davis"
				>
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						hover-opacity="1"
						md-transition="opacity .15s ease 0s"
						md-font="16px/24px sans-serif"
						font="--base"
						color="--dark"
						opacity=".5"
						transition="opacity .15s ease 0s"
						text-transform="initial"
						md-color="--dark"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						opacity="1"
						color="--primary"
						cursor="default"
						md-opacity="1"
						md-cursor="default"
					/>
					<Override
						slot="item"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="link-work">
						AI Student Collective
					</Override>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
			</Components.BurgerMenu>
		</Section>
		<Section
			padding="90px 0px 100px 0px"
			border-style="solid"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			quarkly-title="About"
			box-sizing="border-box"
			border-width="0px 0px 0px 0px"
			md-padding="50px 0px 50px 0px"
			sm-padding="0px 0 0px 0"
			border-color="--color-light"
		>
			<Override
				slot="SectionContent"
				sm-padding="24px 0px 24px 0px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
				margin="0px 32px 0px 32px"
			/>
			<Text
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-text-align="center"
				font="--base"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				text-align="center"
			>
				A Message from our founder
			</Text>
			<Text
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="normal 500 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				margin="0px 0px 0px 0px"
			>
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					"AI will impact all humans. For AI to improve human lives equally - we need diverse teams building and managing it."
					<br />
					{" "}
				</Em>
			</Text>
			<Text margin="0px 0px 0px 0px" font="400 24px --fontFamily-sans">
				- Beena Ammanath (Founder, Humans for AI)
			</Text>
		</Section>
		<Section
			md-padding="25px 0px 25px 0px"
			justify-content="center"
			padding="70px 0 70px 0"
			quarkly-title="Info"
			lg-padding="50px 0px 50px 0px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				lg-grid-template-columns="1fr"
				display="flex"
				grid-template-columns="2fr 3fr"
				grid-gap="64px"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				padding="0px 5% 0px 5%"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="100%"
					align-self="center"
					align-content="flex-start"
				>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
					>
						Who We Are
					</Text>
					<Text
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
					>
						Humans for AI is a multidisciplinary team of passionate volunteers, ranging from business leaders to university students. With volunteers from across the globe, we believe in a future workforce as diverse as the real world. Thats why each member of the Humans for AI team is working tirelessly to create a B.O.L.D future!
					</Text>
					<Link
						color="--light"
						padding="8px 18px 8px 18px"
						text-align="center"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						font="--base"
						letter-spacing="0.5px"
						margin="9px 0px 0px 0px"
						background="--color-primary"
						hover-transform="translateY(-4px)"
						href="/team"
					>
						Our Team
					</Link>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20:23:31.998Z"
					display="inline-block"
					height="auto"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/who%20we%20are.JPG?v=2023-11-20T20%3A23%3A31.998Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
			<Box
				md-margin="44px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				display="flex"
				grid-template-columns="3fr 2fr"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				lg-margin="64px 0px 0px 0px"
				grid-gap="64px"
				margin="96px 0px 0px 0px"
				padding="0px 05% 0px 5%"
			>
				<Image
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20:23:23.309Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/what%20we%20do%20.jpg?v=2023-11-20T20%3A23%3A23.309Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="100%"
				>
					<Text
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-text-align="left"
						sm-font="--headline3"
						md-font="--headline3"
					>
						What We Do
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
					>
						With our programs designed to increase{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							accessibility
						</Strong>
						,{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							literacy
						</Strong>
						, and{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							community
						</Strong>
						{" "}across the tech industry, we hope to provide students and professionals with a range of opportunities to either upskill or reskill before making an impact.
						<br />
						<br />
					</Text>
				</Box>
			</Box>
			<Box
				md-margin="44px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				display="flex"
				grid-template-columns="2fr 3fr"
				grid-gap="64px"
				margin="96px 0px 0px 0px"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				lg-margin="64px 0px 0px 0px"
				padding="0px 5% 0px 5%"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					min-width="100px"
					min-height="100px"
					width="100%"
				>
					<Text
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
						md-font="--headline3"
					>
						Why We Do It
					</Text>
					<Text
						opacity="0.6"
						sm-text-align="left"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
					>
						Currently, the tech industry lacks diversity across a variety of demographics. Given the reach and impact of AI and other emerging technologies, we believe that creating a diverse future workforce is vital in making sure new applications of AI make a positive impact on society. From the Artificial Intelligence Student Collective to established Talent Pipelines, we support marginalized communities, and enable them to contribute to the future of Artificial Intelligence.{" "}
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20:23:32.069Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/why%20we%20do%20it.JPG?v=2023-11-20T20%3A23%3A32.069Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Box
				grid-gap="32px"
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="50px"
						height="50px"
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21:53:55.077Z"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						white-space="nowrap"
						font="--base"
						opacity="0.6"
						text-align="left"
						margin="1px 0px 0px 10px"
						color="--dark"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						href="/index#"
					>
						Humans for AI
					</Link>
				</Box>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap" justify-content="flex-end">
					<LinkBox href="https://www.linkedin.com/company/humansforai/" target="_blank">
						<Icon
							category="ai"
							icon={AiFillLinkedin}
							size="64px"
							padding="0px 3% 0px 3%"
							width="50px"
							height="50px"
							pointer-events="auto"
						/>
					</LinkBox>
					<LinkBox href="https://medium.com/@humansforai" target="_blank">
						<Image src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/icons8-medium-50.svg?v=2023-12-18T23:16:50.399Z" display="block" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
	</Theme>;
});